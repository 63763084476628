import React, { useEffect, useMemo, useState } from 'react';
import { Button, TextField, Grid, InputAdornment, Typography, Divider } from '@material-ui/core';
import { Dialog } from 'components';
import { create, update, show } from './api';

const replacer = '%COMMERCANT%';

const FormDialog = props => {
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');
    const [text, setText] = useState('');
    const [signature, setSignature] = useState('');

    const submit = () => {
        setLoading(true);
        if (props.id) {
            update(props.id, { label, text, signature })
                .then(() => handleClose())
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        } else {
            create({ label, text, signature })
                .then(() => handleClose())
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        }
    };

    const handleClose = () => {
        props.close();
        setLabel('');
        setText('');
        setSignature('');
    };

    const countReplacer = useMemo(() => {
        return (text?.match(new RegExp(replacer, 'g')) || []).length ?? 0;
    }, [text]);

    useEffect(() => {
        if (props.id) {
            setLoading(true);
            show(props.id)
                .then(res => {
                    setLabel(res.label);
                    setText(res.text);
                    setSignature(res.signature);
                })
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        }
    }, [props.id]);

    return (
        <Dialog
            disabled={loading}
            title={props.id ? 'Modifier un modèle AOT' : 'Enregistrer un modèle AOT'}
            onClose={handleClose}
            action={
                <Button disabled={loading} onClick={submit} variant="contained" color="primary">
                    {props.id ? 'Modifier' : 'Enregistrer'}
                </Button>
            }
            open={props.open}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        fullWidth
                        helperText="Saisir un libellé pour l'aot"
                        label="AOT"
                        name="label"
                        onChange={event => setLabel(event.target.value)}
                        required
                        value={label}
                        disabled={loading}
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        helperText="Saisir un texte"
                        label="Texte"
                        name="text"
                        onChange={event => setText(event.target.value)}
                        required
                        value={text}
                        disabled={loading}
                        multiline
                        variant="outlined"
                        rows={10}
                        maxRows={10}
                    />
                </Grid>
                <Grid
                    item
                    style={{ padding: 16, border: '1px solid #bbb', margin: 8, borderRadius: 4 }}>
                    <Typography variant="body2">
                        Pour permettre au document de se compléter automatiquement veuillez
                        remplacer les informations concernés par{' '}
                        <span style={{ padding: 2, border: '1px solid black', borderRadius: 4 }}>
                            <strong>{replacer}</strong>
                        </span>
                    </Typography>
                    <Divider style={{ marginTop: 7, marginBottom: 7 }} />
                    <Typography variant="body2">
                        Nombre d'élément qui seront remplacés par les informations du commerçants :{' '}
                        <strong>{countReplacer}</strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        helperText="Saisir une signature"
                        label="Signature"
                        name="signature"
                        onChange={event => setSignature(event.target.value)}
                        required
                        value={signature}
                        disabled={loading}
                        multiline
                        variant="outlined"
                        rows={5}
                        maxRows={5}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default FormDialog;
