import React, { useState } from 'react';
import env from 'common/env';

import { withStyles, makeStyles } from '@material-ui/styles';
import PrintIcon from '@material-ui/icons/Print';
import ScheduleIcon from '@material-ui/icons/Schedule';

import {
    Grid,
    Paper,
    FormControl,
    Divider,
    Button,
    FormLabel,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Step,
    Stepper,
    StepContent,
    StepLabel,
    StepConnector,
    TextField,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
        //flexGrow: 1
    },
    stepContent: {
        paddingLeft: theme.spacing(5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        margin: -10,
        fontWeight: 500
    },
    stepper: {
        //display: "grid"
        paddingLeft: theme.spacing(5)
    }
}));

const ColorlibConnector = withStyles({
    alternativeLabel: {
        left: 10
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        position: 'relative',
        right: 12,
        backgroundColor: '#3f51b5',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#2979ff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    notActive: {
        backgroundColor: 'white',
        color: '#2979ff',
        border: '1px solid #2979ff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { icon } = props;

    const icons = {
        1: <ScheduleIcon />,
        2: <PrintIcon />
    };

    return <div className={classes.root}>{icons[String(icon)]}</div>;
}

export const BillPackage = () => {
    const classes = useStyles();
    const [merchants, setMerchants] = useState([]);
    const [startAt, setStartAt] = useState('');
    const [endAt, setEndAt] = useState('');

    const handleMerchants = v => {
        const filter = [...merchants];
        const index = filter.indexOf(v);

        if (index > -1) {
            filter.splice(index, 1);
        } else {
            filter.push(v);
        }

        setMerchants(filter);
    };

    const print = () => {
        if (startAt === '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (endAt === '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        if (new Date(startAt).getTime() > new Date(endAt).getTime()) {
            window.alert('La date de début ne doit pas être supérieur à la date de fin');
            return;
        }

        const types = JSON.stringify(merchants);
        window.open(
            `${env}/regie/specific-print-grouped-bills?types=${types}&start_at=${startAt}&end_at=${endAt}`,
            '_blank'
        );
    };

    return (
        <div className={classes.root}>
            <Paper>
                <Typography variant="h4" color="primary" style={{ padding: 20 }}>
                    Génération d'un récapitulatif par chèque uniquement
                </Typography>
                <Divider />
                <Stepper
                    className={classes.stepper}
                    connector={<ColorlibConnector />}
                    orientation="vertical">
                    <Step active expanded key={5}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Sélectionner des dates
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="date"
                                                fullWidth
                                                name="start"
                                                value={startAt}
                                                onChange={e => setStartAt(e.target.value)}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="date"
                                                fullWidth
                                                size="small"
                                                name="end"
                                                value={endAt}
                                                onChange={e => setEndAt(e.target.value)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step active expanded key={2}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Filtrer par typologie de commerçant
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="column"
                                        justify="flex-start"
                                        alignItems="stretch">
                                        <Grid item>
                                            <Grid item>
                                                <FormControl component="fieldset">
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        merchants.findIndex(
                                                                            merchant =>
                                                                                merchant === 'F'
                                                                        ) > -1
                                                                    }
                                                                    name="ta"
                                                                    onChange={e =>
                                                                        handleMerchants('F')
                                                                    }
                                                                />
                                                            }
                                                            label="Titulaire abonné"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        merchants.findIndex(
                                                                            merchant =>
                                                                                merchant === 'V'
                                                                        ) > -1
                                                                    }
                                                                    name="tna"
                                                                    onChange={e =>
                                                                        handleMerchants('V')
                                                                    }
                                                                />
                                                            }
                                                            label="Titulaire non abonné"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        merchants.findIndex(
                                                                            merchant =>
                                                                                merchant === 'E'
                                                                        ) > -1
                                                                    }
                                                                    name="p"
                                                                    onChange={e =>
                                                                        handleMerchants('E')
                                                                    }
                                                                />
                                                            }
                                                            label="Passager"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                endIcon={<PrintIcon />}
                                                color="primary"
                                                onClick={print}>
                                                Imprimer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                </Stepper>
            </Paper>
        </div>
    );
};
