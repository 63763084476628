import env from 'common/env';

/**
 *
 * @param {*} id
 * @returns
 */
export async function getListOfPlaceNumbers(id) {
    const result = await fetch(`${env}/placier/get-list-of-place-numbers/${id}`, {
        method: 'GET'
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des numéros de place.`;
        });

    return result;
}

/**
 *
 * @param {*} id
 * @returns
 */
export async function getSectors(id) {
    const result = await fetch(`${env}/placier/get-sectors/${id}`, {
        method: 'GET'
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des secteurs.`;
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} filter
 * @param {*} search
 * @param {*} page
 * @param {*} order
 * @param {*} orderBy
 * @param {*} beginAt
 * @param {*} mounted
 * @returns
 */
export async function getMerchants(id, filter, search, page, order, orderBy, beginAt, mounted) {
    const formData = new FormData();
    formData.append('filter', JSON.stringify(filter));
    formData.append('search', search);
    formData.append('page', page);
    formData.append('order', order);
    formData.append('orderBy', orderBy);
    formData.append('beginAt', beginAt);
    formData.append('mounted', mounted);

    const result = await fetch(`${env}/placier/get-merchants/${id}`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des commerçants.`;
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} marketDayId
 * @returns
 */
export async function getMerchant(id, marketDayId) {
    const result = await fetch(`${env}/placier/get-merchant/${id}/${marketDayId}`, {
        method: 'GET'
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage du commerçant.`;
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} marketDayId
 * @param {*} type
 * @param {*} status
 * @returns
 */
export async function updateAttendanceRegister(id, marketDayId, type, status) {
    const formData = new FormData();
    formData.append('presence_id', id);
    formData.append('type', type);
    formData.append('status', status);

    const result = await fetch(`${env}/registration/${marketDayId}/update`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de la mise à jour du carnet de présence.`;
        });

    return result;
}

/**
 *
 * @param {*} marketDayId
 * @param {*} selectedActivities
 * @param {*} status
 * @param {*} type
 * @returns
 */
export async function updateAttendanceRegisterInBulkByActivitieByHolder(
    marketDayId,
    selectedActivities,
    status,
    type = 't'
) {
    const formData = new FormData();
    formData.append('presence_id', 'all');
    formData.append('type', type);
    formData.append('status', status);
    formData.append('selectedActivities', JSON.stringify(selectedActivities));

    const result = await fetch(`${env}/registration/${marketDayId}/update`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de la mise à jour du carnet de présence.`;
        });

    return result;
}

/**
 *
 * @param {*} marketDayId
 * @returns
 */
export async function getDraws(marketDayId) {
    const result = await fetch(`${env}/placier/get-draws/${marketDayId}`, {
        method: 'GET'
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des filtres pour le tirage au sort.`;
        });

    return result;
}

/**
 *
 * @param {*} merchant
 * @returns
 */
export async function unsetMerchantFromPlace(merchant) {
    const formData = new FormData();
    formData.append('journalplace_id', merchant.journalplace_id);

    const result = await fetch(`${env}/placement/remove-from-place-journalmarket`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de la libération de la place ${merchant.placeNumber}.`;
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} param
 * @returns
 */
export async function updatePlaceBeforeCashed(id, param) {
    const formData = new FormData();
    formData.append('param', param);

    const result = await fetch(`${env}/finance/update-param-before-cashed/${id}`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la mis à jour de la facture';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @returns
 */
export async function getBill(id) {
    const result = await fetch(`${env}/finance/${id}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw "Echec de l'affichage de la facture";
        });

    return result;
}

/**
 *
 * @param {*} moyenId
 * @param {*} marketDayId
 * @param {*} metres
 * @param {*} banque
 * @param {*} cheque
 * @param {*} id
 * @param {*} stateOfBill
 * @returns
 */
export async function payBill(moyenId, marketDayId, metres, banque, cheque, id, stateOfBill) {
    const formData = new FormData();
    formData.append('cheque', cheque);
    formData.append('banque', banque);
    formData.append('metres', metres);
    formData.append('moyen_paiement_id', moyenId);
    formData.append('journalMarketId', marketDayId);

    const result = await fetch(`${env}/finance/${id}/update/${stateOfBill}`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw 'Une erreur est survenue lors du règlement de la facture.';
        });

    return result;
}

export async function getMerchantDetail(id) {
    const result = await fetch(`${env}/merchant/get-detail-for-placier/${id}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des données.";
        });

    return result;
}

export async function forceUpdateBillingState(marketDayId) {
    const result = await fetch(`${env}/finance/${marketDayId}/force-update`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des données.";
        });

    return result;
}
