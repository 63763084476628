import env from 'common/env';

/**
 *
 * @returns
 */
export async function getConfig() {
    const result = await fetch(`${env}/bill/config`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} payload
 * @returns
 */
export async function setConfig(payload) {
    const formData = new FormData();
    formData.append('bank_identification_statement', payload.bankIdentificationStatement);
    formData.append('additional_information', payload.additionalInformation);
    formData.append('signature', payload.signature);
    formData.append('billing_method', payload.billingMethod);
    formData.append('auto_print_bill', payload.autoPrintBill);

    const result = await fetch(`${env}/bill/config`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la mise à jour';
        });

    return result;
}
