import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    Grid,
    Paper,
    Divider,
    TableCell,
    TableRow,
    Button,
    IconButton,
    Typography,
    Link
} from '@material-ui/core';
import { SearchTextField, MuiTable } from 'components';
import { destroy, index } from './api';

const useStyles = makeStyles(theme => ({
    paperContent: {
        padding: theme.spacing(4)
    }
}));

const headers = [
    { id: 'aot.label', align: 'left', label: 'Libellé', sortable: true },
    { id: 'aot.created_at', align: 'left', label: 'Créé le', sortable: true },
    { id: 'aot.updated_at', align: 'left', label: 'Mis à jour le', sortable: true },
    { id: 'action', align: 'center', label: 'Actions', sortable: false }
];

const All = ({ tab }) => {
    const [count, setCount] = useState(0);
    const [aots, setAots] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headers[0].id);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        !tab && setLoading(true);
    }, [tab]);

    useEffect(() => {
        loading && getData();
    }, [loading]);

    useEffect(() => {
        setLoading(true);
    }, [searchValue, order, orderBy, page]);

    const handleSearchValue = value => {
        setSearchValue(value);
        setPage(0);
        setLoading(true);
    };

    const getData = () => {
        index({ searchValue, page, order, orderBy })
            .then(res => {
                setCount(res.count);
                setAots(res.data);
            })
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    const deleteAot = ({ id, label }) => {
        const confirmed = window.confirm(`Etes-vous sûr de vouloir supprimer : ${label}`);

        if (!confirmed) {
            return;
        }

        destroy(id)
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    return (
        <Paper className={classes.paperContent}>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
                <Grid container direction="row" alignItems="baseline">
                    <Grid item>
                        <IconButton component={RouterLink} to="/settings">
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Link component={RouterLink} to="/settings">
                                Les paramètres
                            </Link>{' '}
                            / Configurer les aots
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <SearchTextField
                        label={`Rechercher un aot`}
                        value={searchValue}
                        onChange={value => handleSearchValue(value)}
                        helperText="Saisir un libellé"
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <MuiTable
                        refresh={() => setLoading(true)}
                        loading={loading}
                        headers={headers}
                        page={{
                            set: val => setPage(val),
                            data: page,
                            count: count
                        }}
                        order={{
                            set: val => setOrder(val),
                            data: order
                        }}
                        orderBy={{
                            set: val => setOrderBy(val),
                            data: orderBy
                        }}>
                        {aots.map((aot, index) => (
                            <TableRow hover key={`${index}-${aot.id}`}>
                                <TableCell align="left" key={`ref-${index}-${aot.id}`}>
                                    {aot.label}
                                </TableCell>
                                <TableCell align="left" key={`created-${index}-${aot.id}`}>
                                    {aot.created_at}
                                </TableCell>
                                <TableCell align="left" key={`updated-${index}-${aot.id}`}>
                                    {aot.updated_at}
                                </TableCell>
                                <TableCell align="center" key={`action-${index}-${aot.id}`}>
                                    <div style={{ display: 'flex', gap: 7 }}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            component={RouterLink}
                                            to={`/settings/aot/edit/${aot.id}`}>
                                            Modifier
                                        </Button>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            size="small"
                                            onClick={() => deleteAot(aot)}>
                                            Supprimer
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </MuiTable>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default All;
