import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableBody,
    ListItemText,
    Button,
    Grid,
    Typography
} from '@material-ui/core';

import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneIcon from '@material-ui/icons/Done';
import { updateAttendanceRegister } from '../../../http';
import env from 'common/env';

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    list: {
        [theme.breakpoints.down('sm')]: {
            height: 'calc(66vh - 105px)'
        },
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 50px)'
        }
    }
}));

const infoPlace = place => {
    let info = (
        <ListItemText primary={<Typography style={{ color: 'green' }}>Disponible</Typography>} />
    );

    if (place.usualPlace == '1') {
        info = (
            <ListItemText
                primary={
                    <Typography style={{ color: 'orange' }}>
                        Place attribuée par défaut {place.onIt == '1' && '(sur cette place)'}
                    </Typography>
                }
                secondary={<Typography style={{ color: 'green' }}>Disponible</Typography>}
            />
        );
    }

    if (place.otherIntel != '0' && place.information == '') {
        if (place.usualPlace == '1') {
            info = (
                <ListItemText
                    primary={
                        <Typography style={{ color: 'orange' }}>
                            Place attribuée par défaut{' '}
                            {place.onIt == '1' && <strong>(sur cette place)</strong>}
                        </Typography>
                    }
                    secondary={
                        <Typography style={{ color: 'red' }}>
                            Occupée par {place.otherIntel}
                        </Typography>
                    }
                />
            );
        } else {
            info = (
                <ListItemText
                    primary={
                        <Typography style={{ color: 'red' }}>
                            Occupée par {place.otherIntel}
                        </Typography>
                    }
                />
            );
        }
    } else if (place.otherIntel != '0' && place.information != '') {
        info = (
            <ListItemText
                primary={
                    <Typography style={{ color: place.usualPlace == '1' ? 'orange' : '#3f51b5' }}>
                        {place.usualPlace == '0' ? 'Recommandée' : 'Place attribuée par défaut'}{' '}
                        {place.onIt == '1' && <strong>(sur cette place)</strong>}
                    </Typography>
                }
                secondary={
                    <Typography style={{ color: 'red' }}>
                        {`Occupée par ${place.otherIntel}`}
                    </Typography>
                }
            />
        );
    } else if (place.otherIntel == '0' && place.information != '') {
        info = (
            <ListItemText
                primary={
                    <Typography style={{ color: place.usualPlace == '1' ? 'orange' : '#3f51b5' }}>
                        {place.usualPlace == '0' ? 'Recommandée' : 'Place attribuée par défaut'}
                    </Typography>
                }
                secondary={<Typography style={{ color: 'green' }}>Disponible</Typography>}
            />
        );
    }

    return info;
};

const List = props => {
    const { className, list, numberOfPages, handlePagination, doPlacement, page, ...rest } = props;
    const classes = useStyles();

    const makeAbsentAndPlace = place => {
        if (window.confirm("Mettre à la place d'un commerçant dont la facture a été encaissée ?")) {
            doPlacement(place, true);
        }
    };

    return (
        <Grid container direction="column" spacing={0} alignItems="stretch">
            <Grid item>
                <TableContainer className={classes.list}>
                    <Table size="small">
                        <TableBody>
                            {list.length > 0 ? (
                                list.map((place, index) => {
                                    return (
                                        <StyledTableRow hover key={index}>
                                            <TableCell
                                                key={`1_${place.id}`}
                                                style={{
                                                    borderLeft: `5px solid ${
                                                        place.onIt == '1'
                                                            ? 'orange'
                                                            : place.otherIntel == '0'
                                                            ? '#10ce10'
                                                            : place.information != ''
                                                            ? '#3f51b5'
                                                            : 'red'
                                                    }`
                                                }}
                                                padding="checkbox"
                                                size="small"
                                                component="th"
                                                scope="row"
                                                align="left">
                                                <ListItemText
                                                    primary={place.numero}
                                                    secondary={place.metres + ' m'}
                                                />
                                            </TableCell>
                                            <TableCell key={`2_${place.id}`} align="left">
                                                <Grid
                                                    container
                                                    padding="checkbox"
                                                    size="small"
                                                    justifyContent="space-between"
                                                    spacing={2}
                                                    alignItems="center">
                                                    <Grid item>{infoPlace(place)}</Grid>
                                                    <Grid item>
                                                        {place.electricity === '1' ? (
                                                            <FlashOnOutlinedIcon
                                                                style={{ color: 'gold' }}
                                                            />
                                                        ) : null}
                                                        {place.water === '1' ? (
                                                            <InvertColorsIcon
                                                                style={{ color: 'dodgerblue' }}
                                                            />
                                                        ) : null}
                                                        {place.vehicule === '1' ? (
                                                            <LocalShippingIcon />
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell
                                                padding="checkbox"
                                                size="small"
                                                key={`3_${place.id}`}
                                                align="center">
                                                {place.encaisse == '1' ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 7
                                                        }}>
                                                        <div>
                                                            Réglé{' '}
                                                            <DoneIcon
                                                                style={{
                                                                    fontSize: 'inherit',
                                                                    color: 'green'
                                                                }}
                                                            />
                                                        </div>
                                                        {env === 'tarascon' || env === 'dev' ? (
                                                            <Button
                                                                onClick={() =>
                                                                    makeAbsentAndPlace(place)
                                                                }
                                                                size="small"
                                                                color="secondary"
                                                                variant="contained"
                                                                style={{ fontSize: 11 }}>
                                                                Forcer le placement
                                                            </Button>
                                                        ) : null}
                                                    </div>
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => doPlacement(place)}
                                                        color="primary"
                                                        style={{
                                                            background:
                                                                place.onIt == '1'
                                                                    ? 'orange'
                                                                    : '#3f51b5'
                                                        }}>
                                                        {place.onIt == '1' ? 'Modifier' : 'Placer'}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })
                            ) : (
                                <StyledTableRow hover key={0} style={{ height: 50 }}>
                                    <TableCell align="center">
                                        Aucun commerçant n'a été trouvé...
                                    </TableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default List;
