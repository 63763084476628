import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import { Grid, Button, Typography, Divider } from '@material-ui/core';

import {
    TotalUsers,
    TotalProfessions,
    City,
    Security,
    EmailTemplateCard,
    AttendancePoint
} from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
});

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            metiers: {
                last: {
                    label: '',
                    date: ''
                },
                number: 0
            },
            users: {
                last: {
                    username: '',
                    profil: ''
                },
                number: 0
            },
            city: {
                city: '',
                mail: '',
                adresse: '',
                codePostal: ''
            },
            attendancePoint: {
                records: 0
            },
            security: [],
            emailTemplates: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/home/settings`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            metiers,
            users,
            city,
            security,
            emailTemplates,
            attendancePoint
        } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={8} direction="column">
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h3" color="primary">
                                    Ville
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ backgroundColor: '#ddd', marginBottom: 20 }} />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <City data={city} />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TotalUsers data={users} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h3" color="primary">
                                    Facturation
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ backgroundColor: '#ddd', marginBottom: 20 }} />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={RouterLink}
                                        to="/settings/bill-config">
                                        Configuration de la facturation
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={RouterLink}
                                        to="/settings/email-template">
                                        Configurer les modèles de mail ({emailTemplates.records})
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={RouterLink}
                                        to="/settings/aot">
                                        Configurer les AOT
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h3" color="primary">
                                    Métiers
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ backgroundColor: '#ddd', marginBottom: 20 }} />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TotalProfessions data={metiers} />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <AttendancePoint data={attendancePoint} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h3" color="primary">
                                    Domaine
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ backgroundColor: '#ddd', marginBottom: 20 }} />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={RouterLink}
                                        to="/settings/unit">
                                        Configurer les unités
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} direction="column" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h3" color="primary">
                                    Sécurité
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ backgroundColor: '#ddd', marginBottom: 20 }} />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Security data={security} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Settings);
