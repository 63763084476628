import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import FormDialog from './FormDialog';
import All from './All';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
}));

const Aot = props => {
    const classes = useStyles();
    const { history } = props;
    const { tab, id } = props.match.params;

    const close = () => {
        history.push('/settings/aot');
    };

    return (
        <div className={classes.root}>
            <FormDialog close={close} open={tab === 'create' || tab === 'edit'} id={id} />
            <Grid container direction="column" spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={'/settings/aot/create'}>
                        Créer un modèle AOT
                    </Button>
                </Grid>
                <Grid item>
                    <All tab={tab} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Aot;
