import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Link,
    Typography,
    CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UserProfile from '../../UserProfile';
import JournalMarche from '../../JournalMarche';

const schema = {
    login: {
        presence: { allowEmpty: false, message: 'est requis' },
        email: false,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'est requis' },
        length: {
            maximum: 128
        }
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        textAlign: 'center',
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    socialButtons: {
        marginTop: theme.spacing(3)
    },
    socialIcon: {
        marginRight: theme.spacing(1)
    },
    sugestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    signInButton: {
        margin: theme.spacing(2, 0)
    }
}));

const SignIn = props => {
    const { history } = props;

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox' ? event.target.checked : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = event => {
        event.preventDefault();

        let login = formState.values.login.toLowerCase();
        let mdp = formState.values.password.toLowerCase();

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/user/sign-in?username=${login}&mdp=${mdp}`
        )
            .then(res => res.json())
            .then(data => {
                if (data) {
                    UserProfile.setId(data.id);
                    UserProfile.setName(data.username);
                    UserProfile.setProfile(data.profile);
                    UserProfile.setDomaine(data.domaine);
                    UserProfile.setRoleId(data.roleId);
                    UserProfile.setToken(data.token);
                    UserProfile.setContributor(data.contributor);
                    UserProfile.setConfig(JSON.stringify(data.config));
                    //UserProfile.setSession();
                    history.push('/home');
                } else {
                    setIsLoading(false);
                    alert('Login ou mot de passe invalide');
                }
            })
            .catch(err => {
                setIsLoading(false);
                alert('Login ou mot de passe invalide');
            });
    };

    const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.quoteContainer} item lg={5}>
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <Typography className={classes.quoteText} variant="h1">
                                Digitalisons nos marchés
                            </Typography>
                            <div className={classes.person}>
                                <Typography className={classes.bio} variant="body1">
                                    By Digit'Marché
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.content} item lg={7} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={event => {
                                    setIsLoading(true);
                                    handleSignIn(event);
                                }}>
                                <Typography className={classes.title} variant="h2">
                                    Se connecter à Digit'Marché
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    Connectez vous avec votre nom d'utilisateur
                                </Typography>

                                <TextField
                                    className={classes.textField}
                                    error={hasError('login')}
                                    fullWidth
                                    helperText={
                                        hasError('login') ? formState.errors.login[0] : null
                                    }
                                    label="Nom d'utilisateur"
                                    name="login"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.login || ''}
                                    variant="outlined"
                                />
                                <TextField
                                    className={classes.textField}
                                    error={hasError('password')}
                                    fullWidth
                                    helperText={
                                        hasError('password') ? formState.errors.password[0] : null
                                    }
                                    label="Mot de passe"
                                    name="password"
                                    onChange={handleChange}
                                    type="password"
                                    value={formState.values.password || ''}
                                    variant="outlined"
                                />
                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={!formState.isValid}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    disabled={isLoading}
                                    variant="contained">
                                    Connexion
                                </Button>
                                {isLoading && <CircularProgress size={35} />}

                                {/*<Typography color="textSecondary" variant="body1">
                                    <Link to="/sign-in" variant="h6">
                                        Mot de passe oublié ?
                                    </Link>
                                </Typography>*/}
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

SignIn.propTypes = {
    history: PropTypes.object
};

export default SignIn;
