import env from 'common/env';

export async function index(payload) {
    const formData = new FormData();
    formData.append('search', payload?.searchValue ?? '');
    formData.append('order', payload?.order ?? 'asc');
    formData.append('orderBy', payload?.orderBy ?? 'aot.label');
    formData.append('page', payload?.page ?? 1);

    const result = await fetch(`${env}/aot/`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des aots';
        });

    return result;
}

/**
 *
 * @param {Array} payload
 */
export async function create(payload) {
    const formData = new FormData();
    formData.append('text', payload.text);
    formData.append('label', payload.label);
    formData.append('signature', payload.signature);

    const result = await fetch(`${env}/aot/create`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la création';
        });

    return result;
}

/**
 *
 * @param {Array} payload
 */
export async function update(id, payload) {
    const formData = new FormData();
    formData.append('text', payload.text);
    formData.append('label', payload.label);
    formData.append('signature', payload.signature);

    const result = await fetch(`${env}/aot/update/${id}`, {
        method: 'POST',
        body: formData
    })
        .then(res => res.ok)
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la mis à jour';
        });

    return result;
}

export async function show(id) {
    const result = await fetch(`${env}/aot/show/${id}`)
        .then(res => (res.ok ? res.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw "Echec d'affichage de l'aot";
        });

    return result;
}

export async function destroy(id) {
    const result = await fetch(`${env}/aot/delete/${id}`, { method: 'DELETE' })
        .then(res => res.ok)
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de suppression';
        });

    return result;
}
