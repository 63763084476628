import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Paper, Grid, Typography, Divider } from '@material-ui/core';

const styles = theme => ({
    root: {
        position: 'relative',
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3),
        paddingBottom: 0
    },
    paperContentDetail: {
        padding: theme.spacing(3)
        //paddingBottom: 0
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class GlobalBillSynthesis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            encaisse: 0,
            nonencaisse: 0,
            encaisse_total: 0,
            nonencaisse_total: 0,
            especes: 0,
            cheque: 0,
            cb: 0,
            prelevement: 0,
            tel: 0
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    formatCash = value => {
        return value + ' €';
    };

    getData = () => {
        const { id } = this.props;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/synthesis/${id}/get-global-bill`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            encaisse,
            nonencaisse,
            encaisse_total,
            nonencaisse_total,
            className,
            especes,
            cb,
            cheque,
            tel,
            prelevement
        } = this.state;

        const { id, filter } = this.props;

        return (
            <Grid container direction="column" alignItems="stretch" spacing={2}>
                <Grid item>
                    <Paper className={clsx(classes.root, className)} style={{ overflow: 'hidden' }}>
                        <div className={classes.paperContent}>
                            <Grid container direction="column" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Typography align="left" color="primary" variant="h4">
                                        Les recettes globales
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        style={{ width: '100%' }}>
                                        <Grid
                                            item
                                            container
                                            xs={6}
                                            direction="column"
                                            justify="center"
                                            alignItems="flex-start">
                                            <Grid item>
                                                <Typography variant="h3" style={{ color: 'green' }}>
                                                    {this.formatCash(encaisse)}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    style={{ color: 'green' }}>
                                                    {encaisse_total} réglée(s) au total
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            xs={6}
                                            direction="column"
                                            justify="center"
                                            alignItems="flex-end">
                                            <Grid item>
                                                <Typography variant="h3" style={{ color: 'red' }}>
                                                    {this.formatCash(nonencaisse)}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    style={{ color: 'red' }}>
                                                    {nonencaisse_total} restant(s) à régler
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
                <Grid item>
                    <Paper className={clsx(classes.root, className)}>
                        <div className={classes.paperContentDetail}>
                            <Grid
                                container
                                direction="row"
                                justify="space-around"
                                alignItems="stretch">
                                <Grid item style={{ width: '20%' }}>
                                    <Grid item>
                                        <Typography align="center" variant="h3">
                                            {this.formatCash(especes)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body1">
                                            Numéraire
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item style={{ width: '20%' }}>
                                    <Grid item>
                                        <Typography align="center" variant="h3">
                                            {this.formatCash(cheque)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body1">
                                            Chèque
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item style={{ width: '20%' }}>
                                    <Grid item>
                                        <Typography align="center" variant="h3">
                                            {this.formatCash(cb)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body1">
                                            Carte Bancaire
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item style={{ width: '20%' }}>
                                    <Grid item>
                                        <Typography align="center" variant="h3">
                                            {this.formatCash(prelevement)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body1">
                                            Prélèvement
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item style={{ width: '20%' }}>
                                    <Grid item>
                                        <Typography align="center" variant="h3">
                                            {this.formatCash(tel)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography align="center" variant="body1">
                                            Téléphone
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(GlobalBillSynthesis);
