import env from 'common/env';

export async function getMerchants() {
    const result = await fetch(`${env}/domaine/get-domain-bill-for-creation`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des commerçants';
        });

    return result;
}

export async function getAots() {
    const formData = new FormData();
    formData.append('search', '');
    formData.append('page', 0);
    formData.append('order', 'asc');
    formData.append('orderBy', 'aot.label');

    const result = await fetch(`${env}/aot/`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des aots';
        });

    return result;
}

export async function getPdf(merchantId, label, text, signature) {
    const formData = new FormData();
    formData.append('merchant_id', merchantId);
    formData.append('label', label);
    formData.append('text', text);
    formData.append('signature', signature);

    const result = await fetch(`${env}/aot/make-pdf`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la génération du pdf';
        });

    return result;
}
