import React from 'react';
import env from 'common/env';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    ButtonGroup,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Button,
    Radio,
    Divider,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    ListItemText,
    FormGroup,
    Checkbox
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import PrintIcon from '@material-ui/icons/Print';

import { Pagination } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'place',
        label: 'N°',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'numero',
        label: 'Facture',
        minWidth: '25%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '50%',
        align: 'left'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '15%',
        align: 'center'
    }
];

class BillSynthesis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            type: 'all',
            list: [],
            total: 0,
            page: 1,
            numberOfPages: 0,
            users: [],
            user: [],
            activities: [],
            billStates: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        // if (prevProps.filter != this.props.filter) {
        //     this.setState({
        //         type: this.props.filter == 't' ? 'ta' : 'p'
        //     });
        // }

        (prevState.type != this.state.type ||
            prevState.page != this.state.page ||
            prevState.user != this.state.user ||
            prevState.searchValue != this.state.searchValue ||
            prevState.activities.length !== this.state.activities.length ||
            prevState.billStates.length !== this.state.billStates.length) &&
            this.getData();
    };

    getData = () => {
        const { type, searchValue, page, user, activities, billStates } = this.state;

        const { id } = this.props;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('page', page);
        formData.append('user', user);
        formData.append('activities', JSON.stringify(activities));
        formData.append('bill_states', JSON.stringify(billStates));

        fetch(`${env}/synthesis/${id}/get-facture-by/${type}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value,
            page: 1
        });
    };

    handleUserFilter = ({ target: { value } }) => {
        this.setState({
            user: value,
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    getPdf = numFacture => {
        window.open(`${env}/regie/print/A/${numFacture}`, '_blank');
    };

    getPdfList = () => {
        const { type } = this.state;

        const { id } = this.props;

        let _confirm = window.confirm('Imprimer la sélection ?');
        _confirm && window.open(`${env}/synthesis/print-get-by?id=${id}&type=${type}`, '_blank');
    };

    formatCash = value => {
        let montants = parseFloat(value)
            .toString()
            .split('.');
        return (
            montants[0] +
            ',' +
            (montants[1] ? (montants[1] + '00')[0] + (montants[1] + '00')[1] : '00') +
            ' €'
        );
    };

    handleActivitiesFilter = activitieId => {
        const activities = [...this.state.activities];
        const index = activities.indexOf(activitieId);

        if (index > -1) {
            activities.splice(index, 1);
        } else {
            activities.push(activitieId);
        }

        this.setState({ activities });
    };

    handleBillStateFilter = billState => {
        const billStates = [...this.state.billStates];
        const index = billStates.indexOf(billState);

        if (index > -1) {
            billStates.splice(index, 1);
        } else {
            billStates.push(billState);
        }

        this.setState({ billStates });
    };

    render() {
        const {
            classes,
            searchValue,
            type,
            list,
            page,
            numberOfPages,
            total,
            users,
            user,
            activities,
            billStates
        } = this.state;

        const { id, filter } = this.props;

        return (
            <Paper className={classes.paperContent}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            justify="space-between"
                            alignItems="baseline">
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    Synthèse de l'encaissement
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    endIcon={<PrintIcon />}
                                    onClick={this.getPdfList}
                                    color="primary">
                                    Imprimer
                                </Button>
                            </Grid>
                            {/*<Grid item>
                                <ButtonGroup fullWidth color="primary">
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/synthesis/t`} color="primary" variant={filter == "t" ? "contained" : "outlined"} >Titulaires</Button>
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/synthesis/p`} color="primary" variant={filter == "p" ? "contained" : "outlined"}>Passagers</Button>
                                </ButtonGroup>
                            </Grid>*/}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Rechercher un commerçant'}
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={searchValue}
                            onChange={this.handleSearch}
                            helperText="Saisir une raison sociale, une enseigne, un nom, un prénom ou un numéro de facture"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel className={classes.labelFilter} component="label">
                                Filtrer par placier
                            </FormLabel>
                            <RadioGroup
                                row
                                name="user"
                                value={user}
                                onChange={this.handleUserFilter}>
                                <FormControlLabel
                                    /*isabled={Boolean(filter == "p")}*/ value="all"
                                    control={<Radio />}
                                    label="Tous"
                                />
                                {users.map(user => (
                                    <FormControlLabel
                                        /*isabled={Boolean(filter == "p")}*/ value={user.id}
                                        control={<Radio />}
                                        label={user.name}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Filtrer par statut
                            </FormLabel>
                            <RadioGroup
                                row
                                name="type"
                                value={type}
                                onChange={this.handleTypeFilter}>
                                <FormControlLabel
                                    /*isabled={Boolean(filter == "p")}*/ value="all"
                                    control={<Radio />}
                                    label="Tous"
                                />
                                <FormControlLabel
                                    /*isabled={Boolean(filter == "p")}*/ value="ta"
                                    control={<Radio />}
                                    label="Titulaires abonnés"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "p")}*/ value="tna"
                                    control={<Radio />}
                                    label="Titulaires non abonnés"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "t")}*/ value="p"
                                    control={<Radio />}
                                    label="Passagers"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Etat de facturation
                            </FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(billStates.find(v => v === 1))}
                                            onChange={e => this.handleBillStateFilter(1)}
                                            name="paid"
                                        />
                                    }
                                    label="Réglé"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(billStates.find(v => v === 0) === 0)}
                                            onChange={e => this.handleBillStateFilter(0)}
                                            name="unpaid"
                                        />
                                    }
                                    label="Non réglé"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Type d'activité
                            </FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 1))}
                                            onChange={e => this.handleActivitiesFilter(1)}
                                            name="alimentaire"
                                        />
                                    }
                                    label="Alimentaire"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 2))}
                                            onChange={e => this.handleActivitiesFilter(2)}
                                            name="primeur"
                                        />
                                    }
                                    label="Primeur"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 3))}
                                            onChange={e => this.handleActivitiesFilter(3)}
                                            name="manu"
                                        />
                                    }
                                    label="Manufacturé"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 4))}
                                            onChange={e => this.handleActivitiesFilter(4)}
                                            name="demo"
                                        />
                                    }
                                    label="Démonstrateur/Posticheur"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ paddingTop: 7, paddingBottom: 0 }}>
                        <Typography variant="body1" color="primary">
                            Nombre de résultat(s) trouvé(s) : {total}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map((bill, index) => {
                                        return (
                                            <TableRow hover key={index}>
                                                {columns.map(column => {
                                                    if (column.id == 'numero') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${
                                                                    bill[column.id]
                                                                }`}
                                                                align={column.align}>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.getPdf(
                                                                                    bill.numero
                                                                                )
                                                                            }>
                                                                            <DescriptionIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <ListItemText
                                                                            primary={bill.numero}
                                                                            secondary={
                                                                                bill.encaisse ==
                                                                                '0' ? (
                                                                                    <React.Fragment>
                                                                                        Non réglé{' '}
                                                                                        <ClearIcon
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    'inherit',
                                                                                                color:
                                                                                                    'red'
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        Réglé{' '}
                                                                                        <DoneIcon
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    'inherit',
                                                                                                color:
                                                                                                    'green'
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'montant') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${
                                                                    bill[column.id]
                                                                }`}
                                                                align={column.align}>
                                                                {this.formatCash(bill[column.id])}
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={`${column.id}_${bill[column.id]}`}
                                                            align={column.align}>
                                                            {bill[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(BillSynthesis);
