import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Button } from '@material-ui/core';

import {
    TotalAdded,
    TotalJournalMarket,
    All,
    Search,
    Creation,
    Edit,
    Dashboard
} from './components';

import TabControl from './TabControl';
import UserProfile from 'UserProfile';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
});

class Markets extends React.Component {
    constructor(props) {
        super(props);

        const { cat, args1, args2 } = this.props.match.params;

        this.state = {
            ...props,
            cat: cat,
            args1: args1,
            args2: args2,
            totalAdded: {
                number: 0,
                last: {
                    label: '',
                    date: ''
                }
            },
            totalJournal: {
                number: 0,
                last: {
                    label: '',
                    date: ''
                }
            }
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate(prevProps, prevState) {
        const { cat, args1, args2 } = this.props.match.params;

        if (prevState.cat != cat || prevState.args1 != args1 || prevState.args2 != args2) {
            this.setState({
                cat: cat,
                args1: args1,
                args2: args2
            });
            this.getData();
        }
    }

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/home-page`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { classes, cat, totalAdded, totalJournal, args1, args2 } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    {cat != 'creation' && (
                        <Grid
                            container
                            item
                            justify="space-between"
                            spacing={3}
                            alignItems="stretch"
                            wrap="wrap">
                            {UserProfile.getProfile() === 'Administrateur' ? (
                                <Grid item md={6} sm={12} xs={12}>
                                    <TotalAdded cat={cat} data={totalAdded} />
                                </Grid>
                            ) : null}
                            <Grid item md={6} sm={12} xs={12}>
                                <TotalJournalMarket cat={cat} data={totalJournal} />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TabControl value={cat} index={'all'}>
                            <All />
                        </TabControl>
                        <TabControl value={cat} index={'edit'}>
                            <Edit
                                args={{
                                    args1: args1,
                                    args2: args2
                                }}
                                history={this.props.history}
                            />
                        </TabControl>
                        <TabControl value={cat} index={'creation'}>
                            <Creation
                                args={{
                                    args1: args1,
                                    args2: args2
                                }}
                                history={this.props.history}
                            />
                        </TabControl>
                        <TabControl value={cat} index={'search'}>
                            <Search
                                args={{
                                    args1: args1,
                                    args2: args2
                                }}
                                history={this.props.history}
                            />
                        </TabControl>
                        <TabControl value={cat} index={'dashboard'}>
                            <Dashboard
                                args={{
                                    args1: args1,
                                    args2: args2
                                }}
                                history={this.props.history}
                            />
                        </TabControl>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Markets);
