import React, { useEffect, useState } from 'react';

import { Button, Typography } from '@material-ui/core';
import { TabControl, Dialog } from 'components';
import { useHistory } from 'react-router-dom';
import { Loader, Form, Done } from './components';
import { getPdf } from './api';

const GenerateAot = props => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const [label, setLabel] = useState('');
    const [text, setText] = useState('');
    const [signature, setSignature] = useState('');
    const [merchantId, setMerchantId] = useState('');
    const [file, setFile] = useState('');

    const history = useHistory();

    const openPdf = () => {
        window.open(file, '_blank');
    };

    const validate = () => {
        if (merchantId === null || merchantId === '') {
            alert('Veuillez sélectionner un commerçant');
            return;
        }

        if (label === '') {
            alert('Veuillez saisir un libellé ou sélectionner un article');
            return;
        }

        if (text === '') {
            alert('Veuillez sélectionner un article');
            return;
        }

        setStep(1);
    };

    useEffect(() => {
        if (step === 1) {
            getPdf(merchantId, label, text, signature)
                .then(res => {
                    if (!res) {
                        return;
                    }
                    setFile(res.url);
                    setStep(2);
                })
                .catch(err => {
                    setStep(0);
                    alert(err);
                })
                .finally(() => setLoading(false));
        }
    }, [step]);

    return (
        <Dialog
            title="Génération des AOT"
            loading={loading}
            onClose={() => history.push('/merchants/all')}
            open
            action={
                <>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => validate()}
                        disabled={loading || step > 0}>
                        Génerer
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => openPdf()}
                        disabled={loading || step < 2}>
                        Télécharger
                    </Button>
                </>
            }>
            <TabControl value={step} index={0}>
                <Form
                    merchantId={merchantId}
                    setMerchantId={v => setMerchantId(v)}
                    label={label}
                    setLabel={v => setLabel(v)}
                    text={text}
                    setText={v => setText(v)}
                    signature={signature}
                    setSignature={v => setSignature(v)}
                />
            </TabControl>
            <TabControl value={step} index={1}>
                <Loader />
            </TabControl>
            <TabControl value={step} index={2}>
                <Done />
            </TabControl>
        </Dialog>
    );
};

export default GenerateAot;
