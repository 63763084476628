import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import HomeIcon from '@material-ui/icons/Home';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import EventIcon from '@material-ui/icons/Event';
import WarningIcon from '@material-ui/icons/Warning';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeckIcon from '@material-ui/icons/Deck';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SidebarNav } from './components';
import { showNewDashboard } from 'common/env';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240
    },
    bottomLogo: {
        overflow: 'hidden',
        textAlign: 'right',
        '& img': {
            width: 35
        }
    },
    logo: {
        display: 'flex',
        backgroundColor: '#403f58',
        flexDirection: 'column',
        minHeight: 64,
        height: 64,
        maxHeight: 64,
        alignItems: 'center',
        margin: '-16px -16px 0px -16px',
        '& img': {
            width: 160,
            marginTop: 18
        }
    },
    root: {
        backgroundColor: '#43425D',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    }
}));

const Sidebar = props => {
    const { open, variant, onClose, className, window, roleId, journalId, ...rest } = props;

    const classes = useStyles();
    const container = window !== undefined ? () => window().document.body : undefined;

    const pages = [
        [
            // Marché / admin
            [
                {
                    title: 'Les marchés',
                    href: '/markets/all',
                    icon: <StorefrontIcon />,
                    disable: false
                },
                {
                    title: 'Les commerçants',
                    href: '/merchants/all',
                    icon: <PeopleIcon />,
                    disable: false
                },
                {
                    title: 'Les paramètres',
                    href: '/settings',
                    icon: <SettingsIcon />,
                    disable: false
                }
            ],

            // Marché / placier
            [
                {
                    title: 'Mon marché',
                    //href: '/dashboard/' + journalId,
                    href: showNewDashboard()
                        ? `/my-market/${journalId}/dashboard`
                        : '/dashboard/' + journalId,
                    icon: <StorefrontIcon />,
                    disable: journalId === 0 || journalId === '0'
                },
                {
                    title: 'Les commerçants',
                    href: '/merchants/all',
                    icon: <PeopleIcon />,
                    disable: false
                }
                /*{
          title: 'Déclarer une observation',
          href: '/report',
          icon: <ReportIcon />,
          disable: false
        },*/
            ],

            // Marché / régisseur
            [
                {
                    title: 'Facturation abonné',
                    href: '/bills/subscribers/view',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation non abonné',
                    href: '/bills/non-subscribers/view',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Retard de paiement',
                    href: '/bills/late/view',
                    //href: '/late-bills',
                    icon: <WarningIcon />,
                    disable: false
                },
                {
                    title: 'Facturation annulée',
                    href: '/bills/canceled/view',
                    //href: '/late-bills',
                    icon: <HighlightOffIcon />,
                    disable: false
                },
                {
                    title: 'Historique des marchés',
                    href: '/markets/search',
                    icon: <StorefrontIcon />,
                    disable: false
                },
                {
                    title: 'Les commerçants',
                    href: '/merchants/all',
                    icon: <PeopleIcon />,
                    disable: false
                }
            ]
        ],

        [
            // Domaine / admin
            [
                {
                    title: 'Les événements',
                    href: '/events/all',
                    icon: <EventIcon />,
                    disable: false
                },
                {
                    title: 'Les terrasses',
                    href: '/new-terrace',
                    icon: <DeckIcon />,
                    disable: false
                },
                {
                    title: 'Les commerces ambulants',
                    href: '/itinerant-shop/all',
                    icon: <StorefrontIcon />,
                    disable: false
                },
                {
                    title: 'Les autres ventes',
                    href: '/other-sales/all',
                    icon: <LocalGroceryStoreIcon />,
                    disable: false
                },
                {
                    title: 'Les commerçants',
                    href: '/domain-merchants/all',
                    icon: <PeopleIcon />,
                    disable: false
                }
            ],

            // Domaine / placier
            [
                {
                    title: 'Facturation événement',
                    href: '/domain-bills',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation terrasse',
                    href: '/new-terrace-bill',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation commerce ambulant',
                    href: '/itinerant-shop-bills',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation fourrière',
                    href: '/pound-bill',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation autre vente',
                    href: '/other-sales-bills',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Les commerçants',
                    href: '/domain-merchants/all',
                    icon: <PeopleIcon />,
                    disable: false
                }
            ],

            // Domaine / régisseur
            [
                {
                    title: 'Facturation événement',
                    href: '/domain-bills',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation terrasse',
                    href: '/new-terrace-bill',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation commerce ambulant',
                    href: '/itinerant-shop-bills',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation fourrière',
                    href: '/pound-bill',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Facturation autre vente',
                    href: '/other-sales-bills',
                    icon: <EuroSymbolIcon />,
                    disable: false
                },
                {
                    title: 'Les commerçants',
                    href: '/domain-merchants/all',
                    icon: <PeopleIcon />,
                    disable: false
                }
            ]
        ]
    ];

    const defaultPages = [
        // Admin
        [
            {
                title: 'Accueil',
                href: '/home',
                icon: <HomeIcon />
            },
            {
                title: 'Boîte mail',
                href: '/mailbox',
                icon: <MailOutlineIcon />,
                disable: false
            },
            {
                title: 'Tableaux de bord',
                href: '/reporting',
                icon: <AssessmentIcon />
            }
        ],
        // Placier
        [
            {
                title: 'Accueil',
                href: '/home',
                icon: <HomeIcon />
            },
            {
                title: 'Boîte mail',
                href: '/mailbox',
                icon: <MailOutlineIcon />,
                disable: false
            }
        ],
        // Régisseur
        [
            {
                title: 'Accueil',
                href: '/home',
                icon: <HomeIcon />
            },
            {
                title: 'Boîte mail',
                href: '/mailbox',
                icon: <MailOutlineIcon />,
                disable: false
            },
            {
                title: 'Etat des recettes',
                href: '/summary-statement',
                icon: <ReceiptIcon />
            }
        ]
    ];

    const disconnected = {
        title: 'Se reconnecter',
        href: '/sign-in',
        icon: <PowerSettingsNewIcon />
    };

    return (
        <Drawer
            anchor="left"
            container={container}
            ModalProps={{
                keepMounted: true
            }}
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}>
            <div {...rest} className={clsx(classes.root, className)}>
                <div className={classes.logo}>
                    <img src="/images/logos/logo-digitmarche.png" />
                </div>

                <SidebarNav
                    onClose={onClose}
                    pages={pages}
                    defaultPages={defaultPages}
                    disconnected={disconnected}
                    roleId={roleId}
                />

                <div className={classes.bottomLogo}>
                    <img src="/images/logos/logo-digitmarche2.png" />
                </div>
            </div>
        </Drawer>
    );
};

export default Sidebar;
