import env from 'common/env';
import React, { useEffect, useMemo, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { getAots, getMerchants } from '../api';

const filler = '%COMMERCANT%';

const Form = ({
    label,
    setLabel,
    text,
    setText,
    signature,
    setSignature,
    setMerchantId,
    merchantId
}) => {
    const [selectedAot, setSelectedAot] = useState(null);
    const [merchants, setMerchants] = useState([]);
    const [fillers, setFillers] = useState([]);
    const [aots, setAots] = useState([]);

    const getData = () => {
        getMerchants()
            .then(res => setMerchants(res.listOfMerchant))
            .catch(err => alert(err));

        getAots()
            .then(res => setAots(res.data))
            .catch(err => alert(err));
    };

    useEffect(() => {
        getData();
    }, []);

    const setAot = aot => {
        setLabel(aot.label);
        setSignature(aot.signature);
        setText(aot.text);
        setFillers(Array.from({ length: countFiller(aot.text) }).map(() => ''));
        setSelectedAot(aot);
    };

    const countFiller = text => {
        return (text?.match(new RegExp(filler, 'g')) || []).length ?? 0;
    };

    const memoCountFiller = useMemo(() => countFiller(selectedAot?.text ?? text), [
        selectedAot?.text ?? text
    ]);

    const setFiller = (index, toFill) => {
        const _fillers = [...fillers];
        _fillers[index] = toFill;

        setFillers(_fillers);

        let parts = selectedAot.text.split(filler);
        let result = '';

        for (let i = 0; i < parts.length - 1; i++) {
            result +=
                parts[i] + (_fillers[i] !== undefined && _fillers[i] !== '' ? _fillers[i] : filler);
        }

        result += parts[parts.length - 1];

        setText(result);
    };

    const reset = () => {
        setFillers(Array.from({ length: countFiller(selectedAot.text) }).map(() => ''));
        setText(selectedAot.text);
    };

    const extractAroundPlaceholder = index => {
        const range = 2;
        const words = selectedAot.text.split(/\s+/);
        const results = [];

        for (let i = 0; i < words.length; i++) {
            if (words[i].includes(filler)) {
                const start = Math.max(0, i - range);
                const end = Math.min(words.length, i + range + 1);
                results.push(words.slice(start, end).join(' '));
            }
        }

        return results[index].replace(filler, '...');
    };

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Autocomplete
                    options={merchants}
                    //size="small"
                    autoHighlight
                    onChange={(_, v) => setMerchantId(v?.id ?? null)}
                    noOptionsText={'Aucun commerçant trouvé'}
                    getOptionLabel={option => option.raisonSociale}
                    getOptionSelected={(option, value) => option.id == value.id}
                    renderInput={params => (
                        <TextField
                            fullWidth
                            {...params}
                            label={'Commerçant'}
                            helperText={'Choisir un commerçant'}
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <Autocomplete
                    options={aots}
                    //size="small"
                    autoHighlight
                    onChange={(_, v) => setAot(v ?? null)}
                    noOptionsText={'Aucune AOT trouvée'}
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, value) => option.id == value.id}
                    renderInput={params => (
                        <TextField
                            fullWidth
                            {...params}
                            label={'AOT'}
                            helperText={'Choisir une AOT'}
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    helperText="Saisir un libellé"
                    label="Article"
                    name="label"
                    onChange={event => setLabel(event.target.value)}
                    required
                    value={label}
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label="Texte"
                    name="text"
                    onChange={event => setText(event.target.value)}
                    required
                    value={text}
                    multiline
                    variant="outlined"
                    rows={10}
                    maxRows={10}
                />
            </Grid>

            {memoCountFiller > 0 ? (
                <Grid
                    item
                    style={{ padding: 24, border: '1px solid #bbb', margin: 14, borderRadius: 4 }}>
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                            justifyContent: 'space-between',
                            paddingBottom: 7
                        }}>
                        <Typography variant="body1">
                            Remplacer les informations marquées par{' '}
                            <span
                                style={{ padding: 2, border: '1px solid black', borderRadius: 4 }}>
                                <strong>{filler}</strong>
                            </span>
                        </Typography>
                        <Button variant="outlined" size="small" onClick={() => reset()}>
                            Réinitialiser
                        </Button>
                    </div>

                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                    {Array.from({ length: memoCountFiller }).map((_, index) => (
                        <TextField
                            style={{ marginTop: 7, marginBottom: 7 }}
                            key={index}
                            fullWidth
                            helperText="Saisir un texte à remplacer"
                            label={extractAroundPlaceholder(index)}
                            name="text"
                            onChange={event => setFiller(index, event.target.value)}
                            required
                            size="small"
                            value={fillers[index]}
                            variant="filled"
                        />
                    ))}
                </Grid>
            ) : null}

            <Grid item>
                <TextField
                    fullWidth
                    helperText="Saisir une signature"
                    label="Signature"
                    name="signature"
                    onChange={event => setSignature(event.target.value)}
                    required
                    value={signature}
                    multiline
                    variant="outlined"
                    rows={5}
                    maxRows={5}
                />
            </Grid>
        </Grid>
    );
};

export default Form;
