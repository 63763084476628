import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar, Button } from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    number: {
        color: theme.palette.success.main
    },
    icon: {
        height: 32,
        width: 32
    },
    labelAction: {
        width: '100%',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    }
}));

const TotalAdded = props => {
    const { className, data, cat, ...rest } = props;

    const classes = useStyles();

    return (
        <CardActionArea component={RouterLink} to={'/merchants/all'}>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
                elevation={cat == 'all' ? 4 : 1}>
                <CardContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container justify="space-between">
                            <Grid item>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body1">
                                    Commerçants enregistrés
                                </Typography>
                                <Typography variant="h3" className={classes.number} gutterBottom>
                                    {data.number}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                    <PersonAddIcon className={classes.icon} />
                                </Avatar>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                Dernier commerçant créé <strong>{data.last.raisonSociale}</strong>{' '}
                                le <strong>{data.last.date}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <div className={classes.footer}>
                    <Button className={classes.labelAction} color="primary">
                        Voir les commerçants
                    </Button>
                </div>
            </Card>
        </CardActionArea>
    );
};

export default TotalAdded;
