import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    ButtonGroup,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Button,
    Radio,
    Divider,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    FormGroup,
    Checkbox
} from '@material-ui/core';

import { Pagination } from './components';

import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import env from 'common/env';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'numero',
        label: 'N°',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '45%',
        align: 'left'
    },
    {
        id: 'metier',
        label: 'Métier',
        minWidth: '35%',
        align: 'left'
    },
    {
        id: 'metres',
        label: 'Métrage',
        minWidth: '10%',
        align: 'left'
    }
];

class PlacementSynthesis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            type: 'all',
            list: [],
            total: 0,
            page: 1,
            numberOfPages: 0,
            activities: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        // if (prevProps.filter != this.props.filter) {
        //     this.setState({
        //         type: this.props.filter == 't' ? 'tp' : 'pp'
        //     });
        // }

        (prevState.type != this.state.type ||
            prevState.page != this.state.page ||
            prevState.searchValue != this.state.searchValue ||
            prevState.activities.length !== this.state.activities.length) &&
            this.getData();
    };

    getData = () => {
        const { type, searchValue, page, activities } = this.state;

        const { id } = this.props;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('page', page);
        formData.append('activities', JSON.stringify(activities));

        fetch(`${env}/placement/${id}/get-placement-by/${type}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getPdf = () => {
        const { type } = this.state;

        const { id } = this.props;

        let _confirm = window.confirm('Imprimer la sélection ?');
        _confirm && window.open(`${env}/placement/print-get-by?id=${id}&type=${type}`, '_blank');
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value,
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleActivitiesFilter = activitieId => {
        const activities = [...this.state.activities];
        const index = activities.indexOf(activitieId);

        if (index > -1) {
            activities.splice(index, 1);
        } else {
            activities.push(activitieId);
        }

        this.setState({ activities });
    };

    render() {
        const {
            classes,
            searchValue,
            type,
            list,
            page,
            numberOfPages,
            total,
            activities
        } = this.state;

        const { id, filter } = this.props;

        return (
            <Paper className={classes.paperContent}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            justify="space-between"
                            alignItems="baseline">
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    Synthèse du placement
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    endIcon={<PrintIcon />}
                                    onClick={this.getPdf}
                                    color="primary">
                                    Imprimer
                                </Button>
                            </Grid>
                            {/*<Grid item>
                                <ButtonGroup fullWidth color="primary">
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/synthesis/t`} color="primary" variant={filter == "t" ? "contained" : "outlined"} >Titulaires</Button>
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/synthesis/p`} color="primary" variant={filter == "p" ? "contained" : "outlined"}>Passagers</Button>
                                </ButtonGroup>
                            </Grid>*/}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Rechercher un commerçant'}
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={searchValue}
                            onChange={this.handleSearch}
                            helperText="Saisir une raison sociale, une enseigne, un nom, un prénom ou un numéro de place"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Filtrer par statut
                            </FormLabel>
                            <RadioGroup
                                row
                                name="type"
                                value={type}
                                onChange={this.handleTypeFilter}>
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "p")}*/ value="all"
                                    control={<Radio />}
                                    label="Tous"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "p")}*/ value="tp"
                                    control={<Radio />}
                                    label="Titulaires présents"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "p")}*/ value="ta"
                                    control={<Radio />}
                                    label="Titulaires absents"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "t")}*/ value="pp"
                                    control={<Radio />}
                                    label="Passagers placés"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "t")}*/ value="pinp"
                                    control={<Radio />}
                                    label="Passagers inscrits non placés "
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Type d'activité
                            </FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 1))}
                                            onChange={e => this.handleActivitiesFilter(1)}
                                            name="alimentaire"
                                        />
                                    }
                                    label="Alimentaire"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 2))}
                                            onChange={e => this.handleActivitiesFilter(2)}
                                            name="primeur"
                                        />
                                    }
                                    label="Primeur"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 3))}
                                            onChange={e => this.handleActivitiesFilter(3)}
                                            name="manu"
                                        />
                                    }
                                    label="Manufacturé"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(activities.find(v => v === 4))}
                                            onChange={e => this.handleActivitiesFilter(4)}
                                            name="demo"
                                        />
                                    }
                                    label="Démonstrateur/Posticheur"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ paddingTop: 7, paddingBottom: 0 }}>
                        <Typography variant="body1" color="primary">
                            Nombre de résultat(s) trouvé(s) : {total}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map((placement, index) => {
                                        return (
                                            <TableRow hover key={index}>
                                                {columns.map(column => {
                                                    return (
                                                        <TableCell
                                                            key={`${column.id}_${
                                                                placement[column.id]
                                                            }`}
                                                            align={column.align}>
                                                            {placement[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(PlacementSynthesis);
