import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
    Settings as SettingsView,
    SignIn as SignInView,
    //Journal as JournalMarketView,
    //MarketAdd as MarketAddView,
    //MarketEdit as MarketEditView,
    City as CityView,
    Home as HomeView,
    Dashboard as DashboardView,
    DashboardAdmin as DashboardAdminView,
    User as UserView,
    UserAdd as UserAddView,
    UserEdit as UserEditView,
    Profession as ProfessionView,
    ProfessionAdd as ProfessionAddView,
    ProfessionEdit as ProfessionEditView,
    Management as ManagementView,
    Merchants as MerchantsView,
    DomainMerchants as DomainMerchantsView,
    Markets as MarketsView,
    LateBills as LateBillsView,
    //Subscriber as SubscriberView,
    //NoneSubscriberBills as NoneSubscriberBillsView,
    DomainBills as DomainBillsView,
    Events as EventsView,
    SummaryStatement as SummaryStatementView,
    Terrace as TerraceView,
    TerraceBills as TerraceBillsView,
    ItinerantShop as ItinerantShopView,
    ItinerantShopBills as ItinerantShopBillsView,
    OtherSales as OtherSalesView,
    OtherSalesBills as OtherSalesBillsView,
    Reporting as ReportingView,
    Report as ReportView,
    Logout as LogoutView,
    Security as SecurityView,
    MailBox as MailBoxView,
    EmailTemplate as EmailTemplateView,
    Reminder,
    NotFound,
    AttendancePoint,
    Bill,
    Bills as BillsView,
    MyMarket as MyMarketView,
    BillConfig as BillConfigView,
    NewTerrace as NewTerraceView,
    NewTerraceBill as NewTerraceBillView,
    Unit as UnitView,
    Aot as AotView
} from './views';
import Pax from 'views/Pax';
import PoundBill from 'views/PoundBill';

const Routes = props => {
    const userDataSession = {
        userProfile: props.userProfile,
        journalMarket: props.journalMarket
    };

    return (
        <Switch>
            <Redirect exact from="/" to="/sign-in" />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                {...userDataSession}
                path="/sign-in"
            />
            <RouteWithLayout
                component={LogoutView}
                exact
                layout={MinimalLayout}
                {...userDataSession}
                path="/logout"
            />
            {/*<RouteWithLayout
                component={JournalMarketView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/market/journal"
            />
            <RouteWithLayout
                component={MarketAddView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/market/add"
            />
            <RouteWithLayout
                component={MarketEditView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/market/edit/:id"
            />*/}
            <RouteWithLayout
                component={HomeView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/home"
            />
            <RouteWithLayout
                component={SettingsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings"
            />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/dashboard/:id"
            />
            <RouteWithLayout
                component={DashboardAdminView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/dashboard-admin/:id"
            />
            <RouteWithLayout
                component={CityView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/city"
            />
            <RouteWithLayout
                component={UserView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/users"
            />
            <RouteWithLayout
                component={UserAddView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/users/add"
            />
            <RouteWithLayout
                component={UserEditView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/users/edit/:id"
            />
            <RouteWithLayout
                component={ProfessionView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/professions"
            />
            <RouteWithLayout
                component={ProfessionAddView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/professions/add"
            />
            <RouteWithLayout
                component={ProfessionEditView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/professions/edit/:id"
            />
            <RouteWithLayout
                component={BillConfigView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/bill-config"
            />
            <RouteWithLayout
                component={ManagementView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/management/:id/:activeStep/:filter"
            />
            <RouteWithLayout
                component={MerchantsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/merchants/:cat?/:siret?/:tab?"
            />
            <RouteWithLayout
                component={DomainMerchantsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/domain-merchants/:cat?/:siret?/:tab?"
            />
            <RouteWithLayout
                component={MarketsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/markets/:cat/:args1?/:args2?"
            />
            <RouteWithLayout
                component={EventsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/events/:cat/:id?/:step?"
            />
            <RouteWithLayout
                component={LateBillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/late-bills/:start?/:end?/:type?/:notify?"
            />
            {/*<RouteWithLayout
                component={SubscriberView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/subscriber/:cat/:start?/:end?/:type?"
            />
            <RouteWithLayout
                component={NoneSubscriberBillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/none-subscriber-bills/:start?/:end?/:type?"
            />*/}
            <RouteWithLayout
                component={DomainBillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/domain-bills/:start?/:end?/:type?"
            />
            <RouteWithLayout
                component={SummaryStatementView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/summary-statement"
            />
            <RouteWithLayout
                component={TerraceView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/terrace/:cat/:id?"
            />
            <RouteWithLayout
                component={TerraceBillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/terrace-bills/:start?/:end?/:type?"
            />
            <RouteWithLayout
                component={ItinerantShopView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/itinerant-shop/:cat/:id?"
            />
            <RouteWithLayout
                component={ItinerantShopBillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/itinerant-shop-bills/:start?/:end?/:type?"
            />
            <RouteWithLayout
                component={OtherSalesView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/other-sales/:cat/:id?"
            />
            <RouteWithLayout
                component={OtherSalesBillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/other-sales-bills/:start?/:end?/:type?"
            />
            <RouteWithLayout
                component={ReportingView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/reporting/:start?/:end?"
            />
            <RouteWithLayout
                component={ReportView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/report"
            />
            <RouteWithLayout
                component={SecurityView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/security"
            />
            <RouteWithLayout
                component={EmailTemplateView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/email-template/:method?/:id?"
            />
            <RouteWithLayout
                component={MailBoxView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/mailbox/:id?"
            />
            <RouteWithLayout
                component={Reminder}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/reminder/:reminder/:type/:start?/:end?"
            />
            <RouteWithLayout
                component={AttendancePoint}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/attendance-point/:method?/:id?"
            />
            <RouteWithLayout
                component={Bill}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/bill/edit/:id?"
            />
            // Gestion des factures en Régie
            <RouteWithLayout
                component={BillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/bills/:type/:action/:start?/:end?/:notify?"
            />
            <RouteWithLayout
                component={BillsView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/bills/:type/:action/:start?/:end?/:notify?"
            />
            <RouteWithLayout
                component={MyMarketView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/my-market/:id/:page?"
            />
            <RouteWithLayout
                component={NotFound}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/not-found"
            />
            <RouteWithLayout
                component={NewTerraceView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/new-terrace/:tab?/:id?"
            />
            <RouteWithLayout
                component={NewTerraceBillView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/new-terrace-bill/:tab?/:id?"
            />
            <RouteWithLayout
                component={PoundBill}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/pound-bill/:tab?/:id?"
            />
            <RouteWithLayout
                component={UnitView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/unit/:tab?/:id?"
            />
            <RouteWithLayout
                component={AotView}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/settings/aot/:tab?/:id?"
            />
            <RouteWithLayout
                component={Pax}
                exact
                layout={MainLayout}
                {...userDataSession}
                path="/print-bill"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
