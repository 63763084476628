import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    TextField,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Button,
    ListItemText,
    IconButton,
    MenuItem,
    Typography,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import banks from 'common/banks';
import { Dialog } from 'components';
import { payBill, getBill, updatePlaceBeforeCashed, forceUpdateBillingState } from './http';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import payments from 'common/payments';
import { site } from 'common/env';
import UserProfile from 'UserProfile';

const filter = createFilterOptions();

/**
 *
 * @param {*} value
 * @returns
 */
function formatPrice(value) {
    let values = parseFloat(value)
        .toString()
        .split('.');

    return values[0] + ',' + (values[1] ? (values[1] + '00')[0] + (values[1] + '00')[1] : '00');
}

const useStyles = makeStyles(theme => ({
    cell: {
        padding: '10px 10px 10px 24px'
    },
    table: {
        maxHeight: 300,
        borderRight: '1px solid #eee',
        borderLeft: '1px solid #eee'
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    title: {
        //flex: '1 1 100%',
    },
    content: {
        padding: theme.spacing(3),
        flexGrow: 1
    },
    subtitle: {
        padding: '32px 0 0 16px'
    },
    info: {
        textAlign: 'center'
    },
    rotate: {
        transform: 'rotate(180deg)'
    }
}));

const BillForm = props => {
    const { open, close, merchant, id, currentFilter } = props;
    const [loading, setLoading] = useState(false);
    const [myFacture, setBill] = useState(null);
    const [paiementMethod, setPaiementMethod] = useState('');
    const [cheque, setCheque] = useState('');
    const [banque, setBanque] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const classes = useStyles();

    const handleMeters = i => {
        let metres = parseInt(myFacture.metres) + i < 1 ? 1 : parseInt(myFacture.metres) + i;
        let montantTotal = myFacture.prixAuMetre * metres;

        if (myFacture.electricity == '1') {
            montantTotal = parseFloat(myFacture.electricityPrice) + montantTotal;
        }

        if (myFacture.water == '1') {
            montantTotal = parseFloat(myFacture.waterPrice) + montantTotal;
        }

        if (site === 'saintremy' || site === 'saintremy-formation') {
            setLoading(true);
            forceUpdateBillingState(id);
            return;
        }

        setBill({
            ...myFacture,
            metres: metres,
            montant: montantTotal
        });
    };

    const handlePaiementMethod = event => {
        setPaiementMethod(event.target.value);
        setBanque('');
        setCheque('');
    };

    const handleCheque = (key, { target: { value } }) => {
        if (key === 'cheque') {
            setCheque(value);
        } else {
            setBanque(value);
        }
    };

    const updateParameters = (id, params) => {
        setLoading(true);
        updatePlaceBeforeCashed(id, params)
            .then(res => {
                setLoading(false);
                setBill(res);
            })
            .catch(err => {
                alert(err);
                setLoading(false);
            });
    };

    const makePayment = () => {
        let moyenId = 0;

        currencies.map(c => {
            if (c.value == paiementMethod) {
                moyenId = c.id;
            }
        });

        if (paiementMethod == '') {
            return alert('Veuillez renseigner un moyen de paiement');
        }

        if (paiementMethod == 'Chèque') {
            if (cheque == '') {
                return alert('Veuillez indiquer un numéro de chèque valide');
            }

            if (cheque.length != 7) {
                return alert("Le numéro de chèque n'est pas valide (7 caractères obligatoires)");
            }

            if (banque == '') {
                return alert('Veuillez indiquer une banque');
            }
        }

        setLoading(true);

        payBill(
            moyenId,
            id,
            myFacture.metres,
            typeof banque === 'string' ? banque : banque.title,
            cheque,
            merchant.cash_id,
            merchant.ta ? 'ta' : 't+p'
        )
            .then(data => {
                if (UserProfile.getConfig()?.auto_print_bill) {
                    window.location.replace(
                        `/print-bill?back=${window.location.pathname}&filter=${currentFilter}&num_facture=${data}&impression_en_cours.png`
                    );
                }

                setLoading(false);
                // alert('Facture réglée avec succès.');
                close();
            })
            .catch(err => {
                alert(err);
                setLoading(false);
                close();
            });
    };

    const handleOnChangeAutocomplete = (fnc, key, newValue) => {
        if (typeof newValue === 'string') {
            fnc('key', { target: { value: newValue } });
            // fnc(null, { [key]: newValue });
        } else if (newValue && newValue.inputValue) {
            // fnc(null, { [key]: newValue.inputValue });
            fnc('key', { target: { value: newValue.inputValue } });
        } else {
            fnc('key', { target: { value: newValue } });
            // fnc(null, newValue);
        }
    };

    const handleFilterOptionAutocomplete = (options, params, key) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                [key]: `Ajouter "${params.inputValue}"`
            });
        }

        return filtered;
    };

    const handleOptionLabelAutocomplement = (option, key) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }

        return option[key];
    };

    useEffect(() => {
        if (!loading) {
            return;
        }

        getBill(merchant.journalplace_id)
            .then(res => {
                setBill(res);
            })
            .catch(err => {
                alert(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [loading]);

    useEffect(() => {
        if (open) {
            setLoading(true);

            payments()
                .then(res => setCurrencies(res))
                .catch(err => alert(err));
        } else {
            setBill(null);
            setPaiementMethod('');
            setBanque('');
            setCheque('');
        }
    }, [open]);

    const totalAmount = myFacture ? formatPrice(myFacture?.montant) : '0,00';

    return (
        <Dialog
            loading={loading}
            title="Récapitulatif avant encaissement"
            open={open}
            onClose={close}
            action={
                <Button
                    disabled={loading || totalAmount === '0,00'}
                    variant="contained"
                    onClick={() => makePayment()}
                    color="primary">
                    Régler
                </Button>
            }>
            <Table>
                <TableBody>
                    <TableRow key={0} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                        <TableCell padding="none" className={classes.cell} align="left">
                            <ListItemText primary="Raison sociale" />
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <ListItemText
                                primary={
                                    <Typography variant="body1" color="primary">
                                        {merchant?.socialeReason}
                                    </Typography>
                                }
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow key={11}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Métier
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Typography variant="body1" color="primary">
                                {myFacture?.metier}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow key={1} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Marché
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Typography variant="body1" color="primary">
                                {myFacture?.marche}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow key={3}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Date
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Typography variant="body1" color="primary">
                                {myFacture?.dateJour}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow key={5} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Numéro de la place
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Typography variant="body1" color="primary">
                                {myFacture?.numero}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow key={85}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Nombre de mètres
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="body1" color="primary">
                                        {myFacture?.metres} mètres
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center">
                                        <Grid item>
                                            <IconButton
                                                disabled={loading || merchant.ta}
                                                onClick={() => handleMeters(-1)}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                disabled={loading || merchant.ta}
                                                onClick={() => handleMeters(1)}>
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>

                    <TableRow key={56} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Prix du mètre linéaire
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Typography variant="body1" color="primary">
                                {myFacture?.formattedPrice} €
                            </Typography>
                        </TableCell>
                    </TableRow>

                    {myFacture?.waterPrice && (
                        <TableRow key={99}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Eau
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={loading || merchant.ta}
                                            checked={myFacture?.water == '1'}
                                            name="active"
                                            color="primary"
                                            onChange={() =>
                                                updateParameters(myFacture?.id, 'water')
                                            }
                                        />
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    )}

                    {myFacture?.electricityPrice && (
                        <TableRow key={56} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Electricité
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={loading || merchant.ta}
                                            checked={myFacture?.electricity == '1'}
                                            onChange={() =>
                                                updateParameters(myFacture?.id, 'electricity')
                                            }
                                            name="active"
                                            color="primary"
                                        />
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    )}

                    <TableRow key={5628}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            Moyen de paiement
                        </TableCell>
                        <TableCell
                            className={classes.cell}
                            padding="none"
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                            align="left">
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item>
                                    <TextField
                                        select
                                        fullWidth
                                        size="small"
                                        value={paiementMethod}
                                        onChange={handlePaiementMethod}>
                                        {currencies.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Typography variant="body1" color="primary">
                                                    {option.label}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        disabled={paiementMethod !== 'Chèque'}
                                        options={banks}
                                        value={banque}
                                        freeSolo
                                        autoHighlight
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, newValue) =>
                                            handleOnChangeAutocomplete(
                                                handleCheque,
                                                'banque',
                                                newValue
                                            )
                                        }
                                        filterOptions={(options, params) =>
                                            handleFilterOptionAutocomplete(options, params, 'title')
                                        }
                                        getOptionLabel={option =>
                                            handleOptionLabelAutocomplement(option, 'title')
                                        }
                                        renderOption={option => option.title}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                name="banque"
                                                placeholder="Banque"
                                                size="small"
                                            />
                                        )}
                                    />
                                    {/* <TextField
                                        disabled={paiementMethod !== 'Chèque'}
                                        size="small"
                                        fullWidth
                                        name="banque"
                                        value={banque}
                                        onChange={event => handleCheque('banque', event)}
                                        placeholder="Banque"
                                    /> */}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        disabled={paiementMethod !== 'Chèque'}
                                        size="small"
                                        fullWidth
                                        name="cheque"
                                        value={cheque}
                                        onChange={event => handleCheque('cheque', event)}
                                        placeholder="Chèque"
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>

                    <TableRow key={568} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <strong>Montant total</strong>
                        </TableCell>
                        <TableCell className={classes.cell} padding="none" align="left">
                            <Typography
                                variant="body1"
                                style={{ fontWeight: 'bold' }}
                                color="primary">
                                {totalAmount} €
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Dialog>
    );
};

export default BillForm;
