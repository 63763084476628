import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Paper,
    InputAdornment,
    TextField,
    IconButton,
    Divider,
    ListItemText,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Typography,
    Tooltip,
    Button,
    CircularProgress,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormGroup,
    Checkbox
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { Pagination, CardInfo, Detail } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    bodyContent: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 100
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 350
        },
        [theme.breakpoints.up('lg')]: {
            //maxWidth: 700,
        }
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)',
            cursor: 'pointer'
        }
    },
    selected: {
        '&$selected, &$selected:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.15)',
            cursor: 'auto'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const columns = [
    {
        id: 'contact',
        label: 'Destinataire(s)',
        minWidth: '25%',
        align: 'left'
    },
    {
        id: 'content',
        label: 'Contenu',
        minWidth: '35%',
        align: 'left'
    },
    {
        id: 'sent_at',
        label: 'Date',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Statut',
        minWidth: '15%',
        align: 'left'
    }
];

const WAIT_INTERVAL = 475;
const ENTER_KEY = 13;

class MailBox extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            ...props,
            filter: '',
            searchValue: '',
            page: 1,
            numberOfPages: 0,
            rows: [],
            stats: [],
            totalRecords: 0,
            totalTodayRecords: 0,
            records: 0,
            isLoading: true,
            start: '',
            end: '',
            activities: []
        };
    }

    componentDidMount = () => {
        this.getData();
        this.getData('GET');
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isLoading && prevState.isLoading != this.state.isLoading) {
            this.getData();
            this.getData('GET');
        }

        !this.props.match.params.id &&
            this.props.match.params.id != prevProps.match.params.id &&
            this.setState({
                customId: false
            });
    };

    getData = (_method = 'POST') => {
        const { page, searchValue, start, end, filter, activities } = this.state;

        const args = {
            method: 'GET'
        };

        if (_method == 'POST') {
            const formData = new FormData();
            formData.append('filter', filter);
            formData.append('page', page);
            formData.append('activities', JSON.stringify(activities));
            args.method = 'POST';
            args.body = formData;
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/mailbox?search=${searchValue}&start=${start}&end=${end}`,
            args
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleSearch = ({ target: { value } }) => {
        clearTimeout(this.timer);

        this.timer = setTimeout(this.refresh, WAIT_INTERVAL);

        this.setState({
            searchValue: value,
            isLoading: false,
            page: 1
        });
    };

    handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY) {
            this.refresh();
        }
    };

    refresh = () => {
        this.setState({
            isLoading: true,
            customId: false
        });
    };

    handlePagination = value => {
        this.setState({
            page: value,
            isLoading: true
        });
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleDateForm = () => {
        const { start, end } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            isLoading: true
        });
    };

    handleFilter = value => {
        this.setState(prevState => ({
            filter: prevState.filter == value ? '' : value,
            isLoading: true,
            page: 1
        }));
    };

    handleActivitiesFilter = activitieId => {
        const activities = [...this.state.activities];
        const index = activities.indexOf(activitieId);

        if (index > -1) {
            activities.splice(index, 1);
        } else {
            activities.push(activitieId);
        }

        this.setState({ activities, isLoading: true });
    };

    render() {
        const {
            classes,
            searchValue,
            rows,
            records,
            isLoading,
            page,
            numberOfPages,
            stats,
            start,
            end,
            filter,
            activities
        } = this.state;

        return (
            <div className={classes.root}>
                {this.props.match.params.id && <Detail customId={this.props.match.params.id} />}
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={2} direction="row">
                            {Object.keys(stats).map((key, index) => {
                                if (stats[key].stat) {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <CardInfo
                                                key={index}
                                                filter={filter}
                                                setFilter={this.handleFilter}
                                                data={{
                                                    key: stats[key].key,
                                                    icon: stats[key].icon,
                                                    description: stats[key].description,
                                                    color: stats[key].color,
                                                    title: stats[key].label,
                                                    records: stats[key].records,
                                                    todayRecords: stats[key].todayRecords
                                                }}
                                            />
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}>
                                <Grid item>
                                    <FormControl component="fieldset" required>
                                        <FormLabel
                                            className={classes.labelFilter}
                                            component="label">
                                            Rechercher par date
                                        </FormLabel>
                                    </FormControl>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                type="date"
                                                fullWidth
                                                name="start"
                                                value={start}
                                                onChange={this.handleDate}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                type="date"
                                                fullWidth
                                                size="small"
                                                name="end"
                                                value={end}
                                                onChange={this.handleDate}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                onClick={this.handleDateForm}>
                                                Rechercher
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel
                                            component="legend"
                                            className={classes.labelFilter}>
                                            Type d'activité
                                        </FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Boolean(
                                                            activities.find(v => v === 1)
                                                        )}
                                                        onChange={e =>
                                                            this.handleActivitiesFilter(1)
                                                        }
                                                        name="alimentaire"
                                                    />
                                                }
                                                label="Alimentaire"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Boolean(
                                                            activities.find(v => v === 2)
                                                        )}
                                                        onChange={e =>
                                                            this.handleActivitiesFilter(2)
                                                        }
                                                        name="primeur"
                                                    />
                                                }
                                                label="Primeur"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Boolean(
                                                            activities.find(v => v === 3)
                                                        )}
                                                        onChange={e =>
                                                            this.handleActivitiesFilter(3)
                                                        }
                                                        name="manu"
                                                    />
                                                }
                                                label="Manufacturé"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Boolean(
                                                            activities.find(v => v === 4)
                                                        )}
                                                        onChange={e =>
                                                            this.handleActivitiesFilter(4)
                                                        }
                                                        name="demo"
                                                    />
                                                }
                                                label="Démonstrateur/Posticheur"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={'Rechercher dans les messages'}
                                        type="text"
                                        fullWidth
                                        value={searchValue}
                                        onChange={this.handleSearch}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            this.handleSearch({
                                                                target: { value: '' }
                                                            })
                                                        }
                                                        edge="end">
                                                        <ClearIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                justify="space-between"
                                                alignItems="flex-end">
                                                <Grid item>
                                                    <Typography color="primary">
                                                        Nombre de résultats : {records}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        spacing={2}
                                                        alignItems="flex-end">
                                                        {isLoading && (
                                                            <Grid item>
                                                                <CircularProgress size={25} />
                                                            </Grid>
                                                        )}

                                                        <Grid item>
                                                            <Button
                                                                onClick={this.refresh}
                                                                disabled={isLoading}
                                                                startIcon={<RefreshIcon />}
                                                                color="primary"
                                                                variant="contained"
                                                                size="small">
                                                                Actualiser
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <TableContainer>
                                                <Table
                                                    stickyHeader
                                                    size="small"
                                                    className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map(column => (
                                                                <StyledTableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{
                                                                        width: column.minWidth
                                                                    }}>
                                                                    {column.label}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row, index) => {
                                                            return (
                                                                <StyledTableRow
                                                                    hover
                                                                    key={index}
                                                                    component={RouterLink}
                                                                    to={`/mailbox/${row.custom_id}`}>
                                                                    <TableCell
                                                                        key={`id_${row.custom_id}`}
                                                                        align="left"
                                                                        style={{ maxWidth: 175 }}>
                                                                        <Tooltip
                                                                            title={row.contact}
                                                                            interactive
                                                                            placement="top">
                                                                            <Typography
                                                                                variant="body1"
                                                                                noWrap>
                                                                                {row.number > 1 &&
                                                                                    `(${row.number})`}{' '}
                                                                                {row.contact}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        key={`content_subject_${row.custom_id}`}
                                                                        align="left"
                                                                        className={
                                                                            classes.bodyContent
                                                                        }>
                                                                        <ListItemText
                                                                            primary={row.subject}
                                                                            secondaryTypographyProps={{
                                                                                variant: 'body2',
                                                                                noWrap: true
                                                                            }}
                                                                            secondary={
                                                                                row.unformattedContent
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        key={`sent_at_${row.custom_id}`}
                                                                        align="left">
                                                                        <ListItemText
                                                                            primary={row.sentAtDate}
                                                                            secondary={
                                                                                row.sentAtHours
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        key={`status_${row.custom_id}`}
                                                                        align="left"
                                                                        style={{ minWidth: 175 }}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            spacing={1}>
                                                                            <Grid item>
                                                                                <Typography variant="body1">
                                                                                    <FiberManualRecordIcon
                                                                                        fontSize="small"
                                                                                        style={{
                                                                                            color:
                                                                                                row
                                                                                                    .status
                                                                                                    .color
                                                                                        }}
                                                                                    />
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    align="left">
                                                                                    {
                                                                                        row.status
                                                                                            .label
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                numberOfPages={numberOfPages}
                                                currentPage={page}
                                                handlePagination={this.handlePagination}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(MailBox);
