import React from 'react';
import { withStyles } from '@material-ui/styles';
import { empty } from 'common/validators';
import {
    TextField,
    Grid,
    InputAdornment,
    FormLabel,
    FormControl,
    Typography,
    FormControlLabel,
    Switch
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class PlaceInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            list: [],
            water: false,
            electricity: false,
            hasWater: false,
            hasElectricity: false,
            merchantOnPlace: false,
            regexp: /^[0-9\b]+$/
        };
    }

    componentDidUpdate = prevProps => {
        ((this.props.merchant && !prevProps.merchant) ||
            prevProps.numFacture != this.props.numFacture) &&
            this.getData();

        this.props.merchantId != prevProps.merchantId && this.setState({ merchantOnPlace: false });

        if (prevProps.selectedPlace !== this.props.selectedPlace) {
            this.setState({
                water: this.props.selectedPlace.water,
                electricity: this.props.selectedPlace.electricity
            });
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { id } = this.props;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/get-places-available-form/${id}`,
            {
                // Your POST endpoint
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                console.error(error);
                //alert('Echec de la récupération des données');
            });
    };

    checkMerchantOnPlace = place => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/check-merchant-on-place-form/${place.id}/${
                this.props.merchantId
            }`,
            {
                // Your POST endpoint
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    merchantOnPlace: data
                });
            })
            .catch(error => {
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleList = (event, value) => {
        this.props.setPlace(value);
        value && this.checkMerchantOnPlace(value);
    };

    handleMeters = ({ target: { value } }) => {
        (value === '' || this.state.regexp.test(value)) &&
            this.props.setMeters(value.replace(/\s/g, '').slice(0, 2));
    };

    renderOptionsForm = option => {
        return option.numero !== undefined
            ? `${option.numero}${option.otherIntel != '0' ? ' - ' : ''}${
                  option.otherIntel != '0' ? option.otherIntel : ''
              }`
            : '';
    };

    handleOptions = ({ target: { name, checked } }) => {
        const selectedPlace = {
            ...this.props.selectedPlace,
            water: this.props.selectedPlace.water ? '1' : false,
            electricity: this.props.selectedPlace.electricity ? '1' : false,
            [name]: checked ? '1' : false
        };

        selectedPlace.metres = this.props.meters;

        this.props.setPlace(selectedPlace);
        this.setState({
            [name]: checked
        });
    };

    render() {
        const { list, merchantOnPlace, hasWater, hasElectricity, water, electricity } = this.state;

        const { selectedPlace, meters, merchant } = this.props;

        return (
            <Grid container spacing={1} direction="column" alignItems="stretch">
                <Grid item>
                    <FormControl required component="fieldset">
                        <FormLabel color="primary">Sélectionnez une place</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={8}>
                            <Autocomplete
                                options={list}
                                name="listOfPlace"
                                autoHighlight
                                disabled={!merchant}
                                value={selectedPlace}
                                onChange={this.handleList}
                                noOptionsText={"Aucune place n'a été trouvée"}
                                getOptionLabel={option => this.renderOptionsForm(option)}
                                getOptionSelected={(option, value) => option.id == value.id}
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps
                                            // startAdornment: (
                                            //     <InputAdornment position="end">N°</InputAdornment>
                                            // )
                                        }}
                                        helperText={
                                            merchantOnPlace ? (
                                                <Typography
                                                    style={{ color: 'red' }}
                                                    fullWidth
                                                    variant="body2"
                                                    error>
                                                    {merchantOnPlace.raisonSociale} est déjà sur
                                                    cette place
                                                </Typography>
                                            ) : !merchant ? (
                                                "Sélectionnez d'abord un commerçant"
                                            ) : (
                                                'Saisir un numéro de place'
                                            )
                                        }
                                        variant="outlined"
                                    />
                                )}
                                renderOption={(option, value) => (
                                    <Grid container direction="column" alignItems="stretch">
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    color:
                                                        option.otherIntel == '0' ? 'green' : 'red'
                                                }}>
                                                {option.numero}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    color:
                                                        option.otherIntel == '0' ? 'green' : 'red'
                                                }}
                                                variant="body2">
                                                {option.otherIntel == '0'
                                                    ? 'Disponible'
                                                    : option.otherIntel}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                disabled={!merchant}
                                value={meters}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">mètres</InputAdornment>
                                    )
                                }}
                                onChange={this.handleMeters}
                            />
                        </Grid>
                        {hasWater || hasElectricity ? (
                            <Grid item xs={12}>
                                <Grid container direction="column" spacing={2}>
                                    {hasWater ? (
                                        <Grid item>
                                            <FormControlLabel
                                                disabled={!merchant}
                                                control={
                                                    <Switch
                                                        checked={water}
                                                        onChange={this.handleOptions}
                                                        name="water"
                                                        color="primary"
                                                    />
                                                }
                                                label="Eau"
                                            />
                                        </Grid>
                                    ) : null}

                                    {hasElectricity ? (
                                        <Grid item>
                                            <FormControlLabel
                                                disabled={!merchant}
                                                control={
                                                    <Switch
                                                        checked={electricity}
                                                        onChange={this.handleOptions}
                                                        name="electricity"
                                                        color="primary"
                                                    />
                                                }
                                                label="Electricité"
                                            />
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(PlaceInput);
